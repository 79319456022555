import { render, staticRenderFns } from "./LicenseInformation.vue?vue&type=template&id=ac9ca21a&scoped=true&"
import script from "./LicenseInformation.vue?vue&type=script&lang=js&"
export * from "./LicenseInformation.vue?vue&type=script&lang=js&"
import style0 from "./LicenseInformation.vue?vue&type=style&index=0&id=ac9ca21a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac9ca21a",
  null
  
)

export default component.exports