<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="3">
        <b-form-input v-model="search" size="sm" placeholder="Suche nach Hostname..." @input="emitSearchChanged"/>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox v-model="filterOptions.software_state.disallowed" inline>
          <b-badge class="mr-2" :variant="variantDanger">Warnung</b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.software_state.noticed" inline>
          <b-badge class="mr-2 border-danger" :variant="variantWarning">
            FG-Anmerkung
          </b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.software_state.commented" inline>
          <b-badge class="mr-2" :variant="variantWarning">Kommentiert</b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.software_state.verified" inline>
          <b-badge class="mr-2 border-success" :variant="variantWarning">
            Verifiziert
          </b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.software_state.allowed" inline>
          <b-badge class="mr-2" :variant="variantSuccess">Erlaubt</b-badge>
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <b-button size="sm" variant="primary" class="mb-2" @click="emitFilterOptions">
          <b-icon-search/>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col cols="3" v-if="fg">
        <b-form-select v-model="filterOptions.team" :options="teamList" size="sm">
          <template #first>
            <b-form-select-option :value="null">Alle Teams</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="3" v-if="fg" >
        <b-form-select v-model="filterOptions.site" :options="siteList" size="sm">
          <template #first>
            <b-form-select-option :value="null">Alle Standorte</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="3">
        <b-form-select v-model="filterOptions.software_id" :options="softwareList" size="sm">
          <template #first>
            <b-form-select-option :value="null">Jede Software</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="3" v-if="!fg">
        <b-form-checkbox v-model="filterOptions.user_only" inline>
            <b-badge class="mr-2" variant="primary">Nur meine Kunden</b-badge>
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto" class="ml-auto" align-self="center">
        <span>Anzahl der Lizenzen: {{ filteredCount }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LicenseFilter",
  props: ['fg', 'filteredCount'],
  data() {
    return {
      search: '',
      filterOptions: {
        site: (this.siteSelected && !this.fg) ? this.siteSelected.shortname : null,
        team: null,
        user_only: false,
        software_id: null,
        software_state: {
          allowed: false,
          disallowed: true,
          commented: false,
          verified: false,
          noticed: true
        }
      }
    }
  },
  methods: {
    ...mapActions(['resetSoftware']),
    ...mapActions(['getSoftwareList']),
    emitSearchChanged() {
      this.$emit('search-changed', this.search)
    },
    emitFilterOptions() {
      if(!this.fg) {
        this.filterOptions.site = this.siteSelected.shortname
      }
      this.$emit('filter-options', this.filterOptions)
    }
  },
  computed: {
    ...mapGetters(['sites']),
    ...mapGetters(['teams']),
    ...mapGetters(['software']),
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeId']),
    siteList() {
      let siteList = []
      _.forEach(this.sites, (site) => {
        siteList.push({value: site.shortname, text: site.displayname})
      })
      return siteList
    },
    teamList() {
      let teamList = []
      _.forEach(this.teams, (team) => {
        teamList.push({value: team.shortname, text: team.displayname})
      })
      return teamList
    },
    softwareList() {
      let softwareList = []
      _.forEach(this.software, (software) => {
        softwareList.push({value: software.id, text: software.displayname})
      })
      return _.sortBy(softwareList, ['text'])
    },
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    }
  },
  beforeMount() {
    this.resetSoftware();
  },
  async mounted() {
    await this.getSoftwareList();
    if(this.$route.query && this.$route.query.software) {
      this.filterOptions.software_id = _.find(this.softwareList, (option) => {
        if(option.text === this.$route.query.software) {
          return true
        }
      }).value
    }
    if(this.$route.query && this.$route.query.hostname) {
      this.search = this.$route.query.hostname
    }
    this.emitSearchChanged()
    this.emitFilterOptions()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Licenses';
</style>
